<template>

        <div class="page talron login-page login-verify-page" style="position: relative;">
            <div class="main-icon uk-flex uk-flex-center uk-margin-medium-bottom">
                <img :src="getDynamicAssetUrl('images/icons/auth-page-icon.svg')" style="max-width: 100%; width: auto; height: 70px;" alt="">
            </div>
            <div class="uk-flex uk-flex-column uk-flex-middle uk-flex-center"  v-if=" ! hasFailed && ! isFinished">
                <div class="main-title tr-font-xl  uk-margin-remove-bottom  uk-text-center uk-text-bold uk-text-primary">
                    {{translate('talron.login.loginVerificationPendingTitle')}}
                </div>

                <p class="uk-margin-remove-top uk-margin-large-bottom">{{translate('talron.login.loginVerificationPendingExplain')}}</p>
                <spinner size="4"></spinner>
            </div>
            <div class="uk-flex uk-flex-column uk-flex-middle uk-flex-center uk-text-center"  v-if="hasFailed">
                <h3 class="tr-text-large uk-margin-large-bottom">{{translate('talron.login.loginStage2GeneralError')}}</h3>
                <form-button @click="$router.push({name:'login'})">
                    {{translate('talron.login.loginStage2FailBackButton')}}
                </form-button>
            </div>

            <div class="uk-flex uk-flex-column uk-flex-middle uk-flex-center uk-text-center" style="" v-if="isFinished && isDesktopSuccess">
                <span class="uk-text-success" uk-icon="icon: check; ratio:3"></span>
                <h3 class="tr-font-large uk-margin-remove-top uk-margin-bottom">{{translate('talron.login.loginStage2DesktopSuccessExplain')}}</h3>
            </div>




        </div>


</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import {useStore} from "vuex";
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";
export default {
    layout: 'default-plain',
    setup (props, context) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());
        let {meta}                                 = useMeta({title: useI18n().t('talron.defaultPage.mainTitle')}).meta

        return {
            asyncOps, asyncOpsReady, asyncStatus,meta
        }
    },
    middleware: {
        noBackFromUser : {},
    },
    data: function () {
        return {
            code : '1',
            hasFailed : false,
            isDesktopSuccess: null,
            isMobileSuccess: null,
            isFinished : false,
        };
    },
    computed: {

    },
    methods: {
        handleLoginSuccessMobile(data) {
            this.isMobileSuccess = true;
            this.isFinished = true;
            this.$store.commit('talron/setUser', data)

            this.$s.ui.notification(this.translate('talron.login.loginStage2SuccessNotificationMobile') , 'success');
            this.$router.push(config.user.loginRedirect);
        },
        async runVerification () {
            let result = await this.asyncOps.asyncCall('operator/verify-personal-id/stage-2', {code: this.code} );
            console.log('veryfy response', result);

            if (result.isError || ! result.data.status) {
                this.$s.ui.notification(this.translate('talron.login.loginStage2GeneralError'), 'danger');
                this.hasFailed = true;
                return false;
            }

            // success behaviors
            if (result.data.uiBehaviour === 'desktop') { // desktop behavior - success screen
                 this.isDesktopSuccess= true;
                 this.isFinished = true;
            } else { // mobile behaviour - allow full login
                this.handleLoginSuccessMobile(result.data);
            }

            return true;




        },
    },
    mounted() {
        if (this.$route.query.verifycode) {
            this.code = this.$route.query.verifycode;
        }
    },
    beforeUnmount() {

    },
    watch: {
        code(newVal) {
            console.log('new code - ', newVal);
            if (newVal) {
                this.runVerification();
            }
        }
    }



}
</script>

<style scoped lang="scss">

</style>
